#frr-notifications-container {
  position: fixed;
  top: 0;
  right: 0;

  display: flex;
  flex-direction: column;
  gap: 8px;

  padding: 80px 8px;
}
