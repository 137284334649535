@import url('scroll-snap-slider');

.scroll-snap-slider {
  overscroll-behavior-x: none;
  overscroll-behavior-y: auto;
}

.scroll-snap-slide {
  scroll-snap-stop: always;
}
