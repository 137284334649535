/* stylelint-disable custom-property-empty-line-before */
body {
  --header-height: 64px;
  --spacing-1: 4px;
  --spacing-2: 8px;
  --spacing-3: 12px;
  --spacing-4: 16px;
  --spacing-5: 20px;
  --spacing-6: 24px;
  --spacing-7: 28px;
  --spacing-8: 32px;
  --spacing-9: 36px;
  --spacing-10: 40px;
  --spacing-11: 44px;
  --spacing-12: 48px;
  --spacing-13: 52px;
  --spacing-14: 56px;
  --spacing-15: 60px;
  --spacing-16: 64px;
  --spacing-17: 68px;
  --spacing-18: 72px;
  --spacing-19: 76px;
  --spacing-20: 80px;

  --shadow-sm: 0px 6px 12px rgb(85 1 41 / 5%);
  --shadow-md: 0px 6px 12px rgb(85 1 41 / 10%);
  --shadow-lg: 0px 8px 20px rgb(85 1 41 / 15%);
}
