/* stylelint-disable custom-property-empty-line-before, comment-empty-line-before, comment-whitespace-inside */
body {
  --typography-heading1: normal 700 32px/40px var(--font-family);
  --typography-heading1-5: normal 700 28px/36px var(--font-family);
  --typography-heading2: normal 700 24px/32px var(--font-family);
  --typography-heading3: normal 700 18px/27px var(--font-family);

  --typography-body1: normal 400 16px/24px var(--font-family);
  --typography-body2: normal 400 14px/20px var(--font-family);
  --typography-body3: normal 400 12px/16px var(--font-family);

  --typography-caption1: normal 600 12px/16px var(--font-family);
  --typography-caption2: normal 600 10px/12px var(--font-family);

  --typography-button1: normal 600 16px/24px var(--font-family);
  --typography-promo: normal 700 40px/52px var(--font-family);
}

/* @TODO add scaling for fonts */
@media (min-width: 768px) {
  body {
    /*--typography-heading1: normal 900 40px/48px var(--font-family);*/
    /*--typography-heading2: normal 900 32px/40px var(--font-family);*/
    /*--typography-heading3: normal 900 24px/32px var(--font-family);*/
    /*--typography-subheading1: normal 700 22px/32px var(--font-family);*/
    /*--typography-subheading2: normal 500 22px/32px var(--font-family);*/
    /*--typography-subheading3: normal 700 20px/32px var(--font-family);*/

    /*--typography-subheading3-letter-spacing: 0.8px;*/
  }
}
